import React from "react";
import CareerDetailsSection from "~sections/Vacante1/CareerDetailsSection"
import { PageWrapper } from "~components/Core";
import FooterSection from "~sections/CareerDetails/Footer";

import FooterOne from "~sections/marketing/FooterOne"


const header = {
    headerClasses:
        "site-header site-header--menu-start dark-header site-header--sticky",
    containerFluid: true,
    // customLogo: Images.HeaderLogo,
}

export default function CareerDetails() {
    return (
        <PageWrapper headerConfig={header}>
            <CareerDetailsSection />

            <FooterOne />
        </PageWrapper>
    )
}
