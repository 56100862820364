import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from '~components';
import CareerCard from './Components/Card'
import Career from './style'
export default function CareerDetailsSection() {
  return (
    <Career className='career_container' backgroundColor="#f3f4f6">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-8 col-lg-10">
            <CareerCard />
          </Col>
        </Row>
        <Row className="row justify-content-center">
          <Col className="col-xl-8 col-lg-10">
            <Career.Wrapper>
              <Career.ContentBlock>
                {/* <Career.Title fontColor="#262729">About the job</Career.Title> */}
                <Career.Text>Estamos creciendo mucho, y buscamos integrar a nuestro equipo personas apasionadas por
                  la tecnología!</Career.Text>
                {/* <Career.Text>In this role, you will work cross-functionally to guide products
              from
              conception to launch by connecting the technical and business worlds. You can break down complex problems
              into steps that drive product development at lightning speed.</Career.Text> */}
              </Career.ContentBlock>
              <Career.ContentBlock>
                <Career.Title fontColor="#262729">¿Qué necesitas?
                </Career.Title>
                {/* <Career.Text>A Senior Data Engineer provides technical and delivery leadership
              for a team of developers working on data integration and processing projects. This role will work with
              stakeholders and other developers to design and implement technical data solutions for the business in a
              way that balances quality, maintainability cost, and time.</Career.Text> */}
                <Career.List>
                  <Career.ListItem>Ingeniería en Sistemas o carrera a fin</Career.ListItem>
                  <Career.ListItem>Conocimiento y experiencia con el desarrollo y mantenimiento de software.</Career.ListItem>
                  <Career.ListItem>Conocimiento en diseño del producto.
                  </Career.ListItem>
                  <Career.ListItem>Conocimiento en la tecnología de la nube.</Career.ListItem>
                  <Career.ListItem>Conocimiento en big data.</Career.ListItem>
                  <Career.ListItem>Capacidad de escribir código.</Career.ListItem>
                  <Career.ListItem>Conocimiento en tecnología, marketing, ventas y finanzas.</Career.ListItem>
                </Career.List>
              </Career.ContentBlock>
              <Career.ContentBlock>
                <Career.Title fontColor="#262729">Funciones</Career.Title>
                <Career.List>

                  <Career.ListItem>Monitorear tendencias tecnológicas, sociales y científicas</Career.ListItem>
                  <Career.ListItem>Implementar objetivos para el área de investigación y desarrollo.</Career.ListItem>
                  <Career.ListItem>Identificar oportunidades y riesgos para el negocio.</Career.ListItem>
                  <Career.ListItem>Garantizar que la tecnología de la empresa se utilice de manera eficiente y rentable</Career.ListItem>
                  <Career.ListItem>Implementar estrategias de protección de datos.</Career.ListItem>
                  <Career.ListItem>Investigar y recomendar los sistemas de gestión de contenido más efectivos.</Career.ListItem>
                  <Career.ListItem>Gestionar la investigación y el desarrollo de tecnología, activos de TI e ingresos asociados</Career.ListItem>
                  <Career.ListItem>Desarrollar procedimientos de seguridad.</Career.ListItem>
                </Career.List>
              </Career.ContentBlock>
              <Career.ContentBlock>
                <Career.Title fontColor="#262729">Fuertes Habilidades</Career.Title>
                <Career.List>

                  <Career.ListItem>Resolución de problemas
                  </Career.ListItem>
                  <Career.ListItem>Autogestión y responsabilidad</Career.ListItem>
                  <Career.ListItem>Trabajo en equipo
                  </Career.ListItem>
                  <Career.ListItem>Comunicación</Career.ListItem>
                  {/* <Career.ListItem></Career.ListItem>
                  <Career.ListItem></Career.ListItem> */}
                </Career.List>
              </Career.ContentBlock>
              <Career.ContentBlock>
                <Career.Title fontColor="#262729">Beneficios
                </Career.Title>
                <Career.List>

                  <Career.ListItem>Salario competitivo
                  </Career.ListItem>
                  <Career.ListItem>Seguro de Gastos Médicos Mayores</Career.ListItem>
                  <Career.ListItem>Lugar de trabajo: CDMX 100% Home office
                  </Career.ListItem>
                  <Career.ListItem>Prestaciones de Ley</Career.ListItem>
                  <Career.ListItem>Vacaciones superiores</Career.ListItem>
                  <Career.ListItem>Crecimiento a corto plazo</Career.ListItem>
                </Career.List>
              </Career.ContentBlock>
              {/* <Career.ContentBlock>
                <Career.Title fontColor="#262729">How to apply</Career.Title>
                <Career.Text mb="30px">At Fastland we celebrate everyone and their multiple intersecting identities. We believe that a panorama of experience allows us to make better decisions together and inspires so that we can better serve our customers and community. Our goal is for metsys user to feel deeply connected to their team through mutual value, respect, and belief.</Career.Text>
                <Career.Button to='/'>Apply for this position</Career.Button>
              </Career.ContentBlock> */}
            </Career.Wrapper>
          </Col>
        </Row>
      </Container>
    </Career>

  )
}